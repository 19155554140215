$primaryColor: #66b2ff;
.MuiDateRangePickerDay {
  &-rangeIntervalDayHighlight {
    background-color: $primaryColor !important;
  }
}

.Mui-selected.MuiDateRangePickerDay-day {
  background-color: $primaryColor !important;
}

.MuiPaper {
  &-elevation8 {
    // & > div {
      // & > div:first-child {
      //   display: none;
      // }
    // }
  }
}
@media (max-width: 1900px) {
  .MuiDialogContent {
    &-elevation8 {
      & > div {
        // & > div:first-child {
          // display: none !important;
        // }
      }
    }
  }
}
